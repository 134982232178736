import React, { useState, useEffect } from 'react';
import AdminHeader from './component/AdminHeader';
import Sidebar from './component/Sidebar';
import Content from './component/Content';
import Footer from './component/Footer';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();


  return (
    <div className="grid-container">
      {error && <p>{error}</p>}
      <AdminHeader />
      <Sidebar />
      <Content />
      <Footer />
    </div>
  );
};

export default Dashboard;
