import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BsGrid1X2Fill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsFillGearFill,
  BsBookFill,
  BsPersonVcardFill,
  BsPieChartFill,
  BsFillCaretDownFill,
} from 'react-icons/bs';
import '../Dashboard.css';

import backgroundImage from "./cbnlogotrans.gif";


const DropdownMenu = ({ isOpen, items, onItemClick }) => {
  return (
    <div className={`dropdown-container ${isOpen ? 'active' : ''}`}>
      {items.map((item, index) => (
        <Link
          key={index}
          to={`/admin${item.link}`}
          className='dropdown-item'
          onClick={() => onItemClick(item)}
        >
          {item.label}
        </Link>
      ))}
    </div>
  );
};

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  

  const [dropdownState, setDropdownState] = useState({
    staff: false,
    reports: false,
  });

  const toggleDropdown = (menu) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const handleMenuClick = (menu) => {
    setActiveMenu((prevActiveMenu) => (prevActiveMenu === menu ? null : menu));
  };

  const handleDropdownItemClick = (menu) => {
    setActiveMenu((prevActiveMenu) => (prevActiveMenu === menu ? null : menu));
  };

  const dropdownItems = {
    staff: [
      { label: 'Add a Staff', link: '/Component/Staff/AddStaff' },
      { label: 'Assign a Staff', link: '/Component/Staff/AssignStaff' },
      { label: 'View all', link: '/Component/Staff/ViewStaff' },
      // Add more staff-related dropdown items as needed
    ],
    reports: [
      { label: 'Report 1', link: '/reports/report1' },
      { label: 'Report 2', link: '/reports/report2' },
      // Add more report-related dropdown items as needed
    ],
  };
  
  return (
    <aside id='sidebar'>
      <div className='sidebar-title'>
        <div className='sidebar-brand'>
          <img src={backgroundImage} alt="Logo" className="icon_header" />
        </div>
        <span className='icon-admin close_icon'>X</span>
      </div>

      <ul className='sidebar-list'>
        <li className='sidebar-list-item'>
        <Link to="/admin/Dashboard">
            <BsGrid1X2Fill className='icon-admin' /> Dashboard
        </Link>
        </li>
        <li className='sidebar-list-item'>
          <Link to="/">
            <BsPeopleFill className="icon-admin" /> Complaints
          </Link>
        </li>
        <li className={`sidebar-list-item ${activeMenu === 'staff' ? 'active' : ''}`}>
          <div onClick={() => handleMenuClick('staff')} className="dropdown-btn">
            <BsPersonVcardFill className="icon-admin" /> Staff
            <BsFillCaretDownFill className={`icon-admin caret-icon ${dropdownState.staff ? 'active' : ''}`} onClick={() => toggleDropdown('staff')} />
          </div>
          <DropdownMenu
            isOpen={dropdownState.staff}
            items={dropdownItems.staff}
            onItemClick={handleDropdownItemClick}
          />
        </li>
        <li className={`sidebar-list-item ${activeMenu === 'reports' ? 'active' : ''}`}>
          <div onClick={() => handleMenuClick('reports')} className="dropdown-btn">
            <BsPieChartFill className="icon-admin" /> Reports
            <BsFillCaretDownFill className={`icon-admin caret-icon ${dropdownState.reports ? 'active' : ''}`} onClick={() => toggleDropdown('report')} />
          </div>
          <DropdownMenu
            isOpen={dropdownState.reports}
            items={dropdownItems.reports}
            onItemClick={handleDropdownItemClick}
          />
        </li>
        <li className='sidebar-list-item'>
          <Link>
            <BsFillGearFill className='icon-admin' /> Settings
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
