import React from 'react';
import './faq.css';

const FAQ = () => {
  return (
    <div id="faq" className='faq'>
      <input id='faq-a' type='checkbox' />
      <label htmlFor='faq-a'>
        <p className="faq-heading">How do I submit a complaint?</p>
        <div className='faq-arrow'></div>
        <p className="faq-text">Click on the "Submit a Complaint" button on this page, fill in the required details, and submit. You'll receive a confirmation and regular updates on the progress of your complaint.</p>
      </label>
      <input id='faq-b' type='checkbox' />
      <label htmlFor='faq-b'>
        <p className="faq-heading">What types of issues can I report?</p>
        <div className='faq-arrow'></div>
        <p className="faq-text">You can report any issues related to your banking experience, including but not limited to unauthorized transactions, account discrepancies, card issues, or service quality concerns.</p>
      </label>
      <input id='faq-c' type='checkbox' />
      <label htmlFor='faq-c'>
        <p className="faq-heading">How long does it take to resolve a complaint?</p>
        <div className='faq-arrow'></div>
        <p className="faq-text">Our goal is to resolve complaints promptly. The resolution time may vary depending on the complexity of the issue, but we assure you of our commitment to a swift resolution.</p>
      </label>
      <input id='faq-d' type='checkbox' />
      <label htmlFor='faq-d'>
        <p className="faq-heading">Can I track the status of my complaint?</p>
        <div className='faq-arrow'></div>
        <p className="faq-text">Yes, once you've submitted a complaint, you'll receive regular updates via email or SMS, and you can also check the status by logging into your account.</p>
      </label>
      <input id='faq-e' type='checkbox' />
      <label htmlFor='faq-e'>
        <p className="faq-heading"> What happens after my complaint is resolved?</p>
        <div className='faq-arrow'></div>
        <p className="faq-text">Once your complaint is resolved, you'll be notified, and we encourage you to provide feedback on your experience. Your feedback helps us enhance our services.</p>
      </label>
    </div>
    
  );
};

export default FAQ;
