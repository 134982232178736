import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/heading/Header';
import Footer from './components/footer/Footer';
import AdminLoginPage from './components/admin/AdminLogin';
import Hero from './components/hero/Hero';
import CallToAction from './components/calltoaction/CallToAction';
import CallAction from './components/action/CallAction';
import FAQ from './components/faq/FAQ';
import ComplaintForm from './components/complaints/ComplaintForm';
import DigitalComm from './components/digital/DigitalComm';
import FinEdu from './components/hub/FinEdu';
import Contact from './components/contact/Contact';
import AdminIndex from './components/admin';

import AdminApp from './components/admin/AdminApp';
import Dashboard from './components/admin/Dashboard';
import AdminHeader from './components/admin/component/AdminHeader';
import SideBar from './components/admin/component/Sidebar';
import Content from './components/admin/component/Content';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/admin/*" element={<AdminIndex />} />
          <Route path="/admin/AdminApp" element={<AdminApp />} />
          <Route path="/admin/Dashboard" element={<Dashboard />} />
          <Route path="/admin/AdminHeader" element={<AdminHeader />} />
          <Route path="/admin/Sidebar" element={<SideBar />} />
          <Route path="/admin/Content" element={<Content />} />
          <Route path="/admin/AdminLogin" element={<AdminLoginPage />} />

          <Route path="/ComplaintForm" element={<ComplaintForm />} />
          <Route path="/DigitalComm" element={<DigitalComm />} />
          <Route path="/FinEdu" element={<FinEdu />} />
          <Route path="/Contact" element={<Contact />} />
          <Route
            path="/"
            element={
              <div>
                <Header />
                <Routes>
                  <Route
                    index
                    element={
                      <>
                        <Hero />
                        <CallToAction />
                        <CallAction />
                        <FAQ />
                      </>
                    }
                  />
                </Routes>
                <Footer />
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
