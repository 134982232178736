import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AdminLogin from './AdminLogin';
import AdminDashboard from './Dashboard';
import AdminHeader from './component/AdminHeader';
import SideBar from './component/Sidebar';
import Content from './component/Content';
import AdminIndex from './index';

const AdminApp = () => {
  return (
    <Routes>
      {/* Redirect to AdminLogin when accessing /admin */}
      <Route path="/admin/*" element={<AdminIndex />} />
      <Route path="/admin/AdminLogin" element={<AdminLogin />} />

      {/* Other admin routes */}
      <Route path="/admin/Dashboard" element={<AdminDashboard />} />
      <Route path="/admin/AdminHeader" element={<AdminHeader />} />
      <Route path="/admin/Sidebar" element={<SideBar />} />
      <Route path="/admin/Content" element={<Content />} />
    </Routes>
  );
};

export default AdminApp;
