import React from 'react';
import AdminLogin from './AdminLogin';

const AdminIndex = () => {
  return (
    <div>
      {/* You can render additional content or just redirect to AdminLogin */}
      <AdminLogin />
    </div>
  );
};

export default AdminIndex;
