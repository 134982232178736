import React, { useEffect } from 'react';
import './contact.css';
import Footer from '../footer/Footer';
import Header from '../heading/Header';

const Contact = () => {

  return (
    <>
    <Header />
    <div className="container-contact">
        <br></br>
      <div className="form">
        <div className="contact-info">
          <h3 className="title">Let's get in touch</h3>
          <p className="text">
          No matter the nature of your inquiry, we will connect you with the right individual for a discussion. 
          Our Interactive Contact Centre enables customers to engage with any of our agents at any time, any day. 
          </p>

          <div className="info">
            <div className="information">
              <i className="fas fa-map-marker-alt"></i> &nbsp; &nbsp;
                <p>Director<br />
                Consumer Protection Department<br />
                Central Bank of Nigeria<br />
                Central Business District<br />
                Plot 33, Abubakar Tafawa Balewa Way<br />
                P.M.B 0187, Garki Abuja, Nigeria</p>
            </div>
            <div className="information">
              <i className="fas fa-phone"></i>&nbsp;&nbsp;
              <p>+234 700 225 5226</p>
            </div>
          </div>

          <div className="social-media">
            <p>Connect with us :</p>
            <div className="social-icons">
              <a href="https://www.facebook.com/cenbankng">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/cenbank">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com/centralbankng">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/company/central-bank-of-nigeria-cenbank/">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div><br />
          </div>
        </div>

        <div className="contact-form">
          <form action="index.html" autoComplete="off">
            <h3 className="title">Contact us</h3>
            <div className="input-container">
              <input type="text" name="name" className="input" />
              <label htmlFor="">First Name</label>
            </div>
            <div className="input-container">
              <input type="text" name="name" className="input" />
              <label htmlFor="">Last Name</label>
            </div>
            <div className="input-container">
              <input type="email" name="email" className="input" />
              <label htmlFor="">Email</label>
            </div>
            <div className="input-container">
              <input type="tel" name="phone" className="input" />
              <label htmlFor="">Phone</label>
            </div>
            <div className="input-container">
                <label htmlFor="inquiry"></label>
                <select id="inquiry" name="inquiry" className="input">
                    <option style={{ color: 'black' }}>Your Inquiry:</option>
                    <option style={{ color: 'black' }} value="option1">Dispense errors</option>
                    <option style={{ color: 'black' }} value="option2">Unauthorized transactions or fraudulent activities on accounts.</option>
                    <option style={{ color: 'black' }} value="option3">Mis-selling of financial products such as insurance policies, loans, or investments.</option>
                    <option style={{ color: 'black' }} value="option4">Unfair or deceptive practices by financial institutions.</option>
                    <option style={{ color: 'black' }} value="option5">Billing errors or incorrect charges.</option>
                    <option style={{ color: 'black' }} value="option6">Failure to provide adequate disclosure of terms and conditions</option>
                    <option style={{ color: 'black' }} value="option7">Poor customer service or delays in processing requests.</option>
                    <option style={{ color: 'black' }} value="option8">Disputes related to interest rates, fees, or penalties.</option>
                    <option style={{ color: 'black' }} value="option9">Identity theft or data breaches affecting financial accounts.</option>
                    <option style={{ color: 'black' }} value="option10">Non-compliance with consumer protection regulations or codes of conduct.</option>
                    <option style={{ color: 'black' }} value="option11">Other issues affecting the rights and interests of financial consumers.</option>
                </select>

          </div>
            <div className="input-container textarea">
              <textarea name="message" className="input"></textarea>
              <label htmlFor="">Message</label>
              <span>Message</span>
            </div>
            <input type="submit" value="Send" className="btn" />
          </form>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default Contact;
