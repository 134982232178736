import React, { useState, useEffect } from 'react';
import './complaintform.css';
import Footer from '../footer/Footer';
import Header from '../heading/Header';
import axios from 'axios';

  const ComplaintForm = () => {
    const [banks, setBanks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [formData, setFormData] = useState({
      YourName: '',
      accountName: '',
      accountNumber: '',
      ComplaintTitle: '',
      amount: '',
      currency: '',
      bankName: '',
      otherbankname: '',
      date: '',
      address: '',
      fax: '',
      email: '',
      branchname: '',
      branchstaff: '',
      complaintnature: '',
      DescriptionOfComplaint: '',
      reportedDescription: '',
      photoPath: null,
    });

    useEffect(() => {
      fetch('/banks.json')
        .then((response) => response.json())
        .then((data) => {
          console.log('Fetched data:', data);
          setBanks(data);
        })
        .catch((error) => console.error('Error fetching banks:', error));
    }, []);

    // Function to format the date value
const formatDate = (dateValue) => {
  try {
    // Ensure the date is not empty or undefined
    if (dateValue) {
      const formattedDate = new Date(dateValue).toISOString().split('T')[0];
      return formattedDate;
    } else {
      console.error('Invalid date value. Please enter a valid date.');
      return '';
    }
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

    

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  // Format the date
  const formattedDate = formatDate(formData.date);

  if (formattedDate === null) {
    console.error('Invalid date. Please check the date value.');
    setIsLoading(false);
    return;
  }

  try {
    const formDataForSubmit = new FormData();
    Object.entries({ ...formData, date: formattedDate }).forEach(([key, value]) => {
      formDataForSubmit.append(key, value);
    });
    formDataForSubmit.append('photo', formData.photo);

    const response = await axios.post(
      'https://complaints.tecbank.com.ng/compbk/user-complaints.php',
      formDataForSubmit,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    console.log('PHP Response:', response.data);

    if (response.data.startsWith('Error:')) {
      console.error('Error submitting complaint:', response.data);
      // Add user-friendly error message here
      alert('An error occurred while submitting the complaint. Please try again.');
    } else {
      setIsSuccess(true);
      console.log('Complaint submitted successfully');
    }
  } catch (error) {
    console.error('Error submitting complaint:', error);
    // Add user-friendly error message here
    alert('An error occurred while submitting the complaint. Please try again.');
  } finally {
    setIsLoading(false);
  }
};

      
    
  
   
   
    const handleInputChange = (e) => {
      const { name, value } = e.target;
    
      // Check if the input is a date field and handle it accordingly
      if (name === 'date') {
        // Check if the value is not empty or undefined
        if (value) {
          // Set the formatted date value
          const formattedDate = formatDate(value);
          setFormData((prevData) => ({ ...prevData, [name]: formattedDate }));
        } else {
          // Handle empty or undefined date value
          console.error('Invalid date value. Please enter a valid date.');
        }
      } else {
        // For non-date fields, update the state normally
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      }
    };
    

    const handleFileChange = (e) => {
      const { name, files } = e.target;
      const file = files[0];
      setFormData((prevData) => ({ ...prevData, [name]: file }));
    };
    
  


  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };


  return (
    <>
    <Header />
    <section className="complaint-form">
      <br></br>
      <h2>Complaint Form</h2>
      {isSuccess ? (
          <div className="success-message">
            Complaint submitted successfully.
          </div>
        ) : (
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {/* Basic complaint form fields */}
        <label>
          Your Name:
          <input type="text" name="YourName" onChange={handleInputChange} required />
        </label>

        <label>
          Account Name:
          <input type="text" name="accountName" onChange={handleInputChange} required />
        </label>

        <label>
          Account Number:
          <input type="text" name="accountNumber" onChange={handleInputChange} required />
        </label>

        <label>
          Title of complaint:
          <input type="text" name="ComplaintTitle" onChange={handleInputChange} required />
        </label>

        <label>
          Amount Involved:
          <input type="text" name="amount" onChange={handleInputChange} required />
        </label>

        <label>
          Currency:
          <select name="currency" onChange={handleInputChange}  value={formData.currency || ''}>
                    <option style={{ color: 'black' }}value="naira">Nigeria Naira ₦</option>
                    <option style={{ color: 'black' }} value="cfa">CFA</option>
                    <option style={{ color: 'black' }} value="swiss">Swiss Francs CHF</option>
                    <option style={{ color: 'black' }} value="danish">Danish Kroner kr</option>
                    <option style={{ color: 'black' }} value="euro">Euro €</option>
                    <option style={{ color: 'black' }} value="british">British Pounds Sterling £</option>
                    <option style={{ color: 'black' }} value="dollars">US Dollars $</option>
                    <option style={{ color: 'black' }} value="yen">Japanese Yen ¥</option>
                    <option style={{ color: 'black' }} value="waua">WAUA</option>
            </select>
        </label>

        <label>
          Bank Name:
          <select name="bankName" onChange={handleInputChange} value={formData.bankName || ''} required>
            <option value="" disabled>Select Bank</option>
            {banks.map((bank, index) => (
              <option key={index} value={bank.name}>
                {bank.name}
              </option>
            ))}
          </select>
        </label>

        <label>
          If other, Please specify:
          <input type="text" onChange={handleInputChange} name="otherbankname" />
        </label>

        <label>
          Date of Event:
          <input type="date" onChange={handleInputChange} name="date" required />
        </label>

        <label>
          Address:
          <input type="text" onChange={handleInputChange} name="address" required />
        </label>

        <label>
          Fax:
          <input type="tel" onChange={handleInputChange} name="fax" />
        </label>

        <label>
          Email Address:
          <input type="email" onChange={handleInputChange} name="email" required />
        </label>

        <h3>Branch Complained Against</h3>
        
        <label>
          Branch Name:
          <input type="text" onChange={handleInputChange} name="branchname" />
        </label>

        <label>
          Branch Staff:
          <input type="text" onChange={handleInputChange} name="branchstaff" />
        </label>

        <h3>Nature of Complaint</h3>
        <label>
          Nature of Complaint:
          <select onChange={handleInputChange} name="complaintnature">
          <option style={{ color: 'black' }}>Your Inquiry:</option>
                    <option style={{ color: 'black' }} value="option1">Dispense errors</option>
                    <option style={{ color: 'black' }} value="option2">Unauthorized transactions or fraudulent activities on accounts.</option>
                    <option style={{ color: 'black' }} value="option3">Mis-selling of financial products such as insurance policies, loans, or investments.</option>
                    <option style={{ color: 'black' }} value="option4">Unfair or deceptive practices by financial institutions.</option>
                    <option style={{ color: 'black' }} value="option5">Billing errors or incorrect charges.</option>
                    <option style={{ color: 'black' }} value="option6">Failure to provide adequate disclosure of terms and conditions</option>
                    <option style={{ color: 'black' }} value="option7">Poor customer service or delays in processing requests.</option>
                    <option style={{ color: 'black' }} value="option8">Disputes related to interest rates, fees, or penalties.</option>
                    <option style={{ color: 'black' }} value="option9">Identity theft or data breaches affecting financial accounts.</option>
                    <option style={{ color: 'black' }} value="option10">Non-compliance with consumer protection regulations or codes of conduct.</option>
                    <option style={{ color: 'black' }} value="option11">Other issues affecting the rights and interests of financial consumers.</option>
          </select>
        </label>
        
        <label>
          Brief Description of the Complaint:
          <textarea onChange={handleInputChange} name="DescriptionOfComplaint" rows="4" required></textarea>
        </label>

         
        <label>
        If you have earlier reported this matter to the Bank you are complaining against, please provide details.
          <textarea onChange={handleInputChange} name="reportedDescription" rows="4"></textarea>
        </label>
        {/* Photo upload input */}
        <label>
        Upload Screenshot if applicable
        <input type="file" onChange={handleFileChange} name="photo" accept="image/*" />
      </label>


        {/* Checkbox for agreement to terms and conditions */}
        <label className="checkbox-label">
        Please note that the above information is obtained in order to facilitate easy resolution of complaints. To enable us treat your complaint; please attach all relevant support documents e.g.  Statements, Contract Agreements, etc. in the next step of this form.
Consumer Protection Office, Central Bank of Nigeria.
        </label>


        <button className='button-complaint' type="submit" disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Submit Complaint'}
        </button>

      </form>
      )}
    </section>
    <Footer />
    </>
  );
};

export default ComplaintForm;
