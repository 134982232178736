import React from 'react';
import './callaction.css';

const CallAction = () => {
  const ctaStyle = {
    position: 'relative',
    color: '#fff',
    backgroundColor: 'rgba(0, 128, 0, 0.7)',
    padding: '50px 0',
    textAlign: 'center',
    marginBottom: '30px',
  };

  return (
    <div style={ctaStyle}>
      <h2>Frequently Asked Questions</h2>
    </div>

  );
};

export default CallAction;
