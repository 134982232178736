import React from 'react';
import './calltoaction.css';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <section className="about-section">
      <div className="container">

        {/* Additional Content */}
        <div className="sec-title">
          <span className="title">Voice Your Concerns</span>
          <h2>Raise Your Voice, Resolve the Issue</h2>
        </div>

        {/* Main Text */}
        <div className="text">
          <p>
            Are you facing challenges with your banking experience? We understand that sometimes things don't go as planned. 
            Our Complaints Page empowers you to express your concerns and provides a direct channel to resolve them. 
            Whether it's an unauthorized transaction, account discrepancies, or any other banking issue, 
            let us know; we're here to help.
          </p> <br />

          <p>What Our Complaints Page Can Do for You:</p>
          <br />
          <ul>
            <li>
              <strong>Swift Resolution:</strong> We are committed to resolving your issues as quickly as possible.
            </li>
            <li>
              <strong>Transparent Communication:</strong> Stay informed throughout the resolution process.
            </li>
            <li>
              <strong>Feedback Mechanism:</strong> Your feedback matters; it helps us enhance our services.
            </li>
            <li>
              <strong>Improving Banking Services:</strong> Your complaints contribute to our continuous improvement.
            </li>
          </ul>
        </div>
        <div className="button">
            <Link to="/ComplaintForm" className="nav-link">
                <button className='primary-btn'>
                    Submit a Complaint<i className='fa fa-long-arrow-alt-right'></i>
                </button>
            </Link>
        </div>
        <br />
        <br />
      </div>
    </section>
  );
};

export default CallToAction;
