import React from 'react'
import Footer from '../footer/Footer';
import Header from '../heading/Header';
import './digitalcomm.css';

const DigitalComm = () => {
  return (
    <>
    <Header />
    <div id="content" className="financial-literacy">
        <br></br>
      <div id="ContentTextinner">
        <h3>Complaint Lodgment</h3>
        <p>
          This is a Guide on how and where you can lodge a complaint against Financial Institutions regulated by the Central Bank of Nigeria such as Commercial
          Banks, Microfinance Banks, Primary Mortgage Institutions and Discount Houses.
        </p>
      </div>

      <div id="ContentTextinner">
        <h3>Contact Your Institution First</h3>
          <p>
          The Central Bank of Nigeria (CBN) issued a circular in 2011 directing all banks to expand their existing ATM HELP DESK to handle all types of consumer complaints.
          </p>
          <p>Therefore, if you have a complaint against your bank, you MUST first report the complaint at the bank/branch where the issue originated and then allow 2 weeks (it might be less in some banks) for the issue to be resolved. However, the revised edition of the circular dated 30 th June, 2014 requires banks to resolve complaints on excess charges and loans within thirty (30) days.</p>
      </div>

      <div id="ContentTextinner">
        <h3>If Your Bank Fails to Resolve Your Complain</h3>
          <p>
          If after lodging your complaint your Bank still fails to engage you and resolve the complaint within 2 Weeks or 30 days as the case may be as provided for in the ATM HELP DESK Circular, you have the right to escalate your complaint to the Consumer Protection Department (CPD) of the CBN.
          </p>
      </div>

      <div id="ContentTextinner">
        <h3>Complaints to Consumer Protection Department</h3>
          <p>
          You can only direct your Complaints to CPD upon the failure of your Bank/Financial Institution to resolve your complaint within the 2 weeks timeline or 30 days as the case may be given by the CBN.
          </p>
      </div>

      <div id="ContentTextinner">
        <h3>Does the CPD Deal with all Types of Complaints</h3>
          <p>
          The CBN deals with all financial related complaints so far as it is against Financial Institutions within its regulatory purview.
          </p>
      </div>

      <div id="ContentTextinner">
        <h3>How to Submit your Complaints</h3>
          <p>
          When you click the "Submit Complaint" button on either the homepage or in the menu, you will be redirected to a form page where our agents will promptly address all your complaints.
          </p>
      </div>
    </div>
    <Footer />
    </>
  )
}

export default DigitalComm