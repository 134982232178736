import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ForgotPassword from './ForgotPassword'; 
import './adminlogin.css';

const AdminLoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false); 
  const navigate = useNavigate();


  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    console.log('Username:', e.target.value);
  };
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    console.log('Password:', e.target.value);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Form Submitted', username, password);
  
    try {
      const formData = new FormData(e.target);
      console.log('Form Data:', formData); // Add this line
  
      const response = await fetch('https://complaints.tecbank.com.ng/compbk/admin-login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        credentials: 'include',
      });      
  
      const result = await response.json();
      console.log('Result:', result);
  
      if (result.success) {
        // Set the token in localStorage or sessionStorage
        localStorage.setItem('adminToken', result.adminId);
        console.log('Login successful');
        setSuccessMessage(result.message);
        navigate('/admin/Dashboard');
      } else {
        // Handle failed login (display error message, etc.)
        console.error('Login failed:', result.message);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };
  
  

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
  };

  return (
    <div className="admin-login-page">
      <div className="admin-login-form">
        <h3>Please Enter Your Login Details</h3>
        {successMessage && <p style={{ color: 'green', fontWeight: 'bold' }}>{successMessage}</p>}

        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Username/Email:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={handleUsernameChange}
            required
          />

          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
            <p onClick={handleForgotPasswordClick} style={{ cursor: 'pointer' }}>
            Forgot Password?
          </p>

          <button type="submit">Login</button>
        </form>

        {showForgotPassword && <ForgotPassword />}
      </div>
    </div>
  );
};

export default AdminLoginPage;
