import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Enrollment from './Enrollment';
import '../Dashboard.css'

const Content = () => {
  return (
    <main className='main-container'>
      {/* Other components can be added here */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Enrollment" element={<Enrollment />} />
        {/* Add more routes for other components */}
      </Routes>
    </main>
  );
}

export default Content;
