import React from 'react'
import Footer from '../footer/Footer';
import Header from '../heading/Header';
import './finedu.css';

const FinEdu = () => {
  return (
    <>
    <Header />
    <div id="content" className="financial-literacy">
      <br></br>
      <div id="ContentTextinner">
          <h3>Bank Customers’ Bill Of Rights And Duties</h3>
          <p>The Customer is the most important person in the economy and every business succeeds only when the customer is happy. This explains why the customer is regarded as King. As a King, the customer has many rights. But a King also has duties which he owes himself and the economy.</p>
          <p>In Nigeria, customers of banks have certain rights and duties guaranteed by law, regulation and conventions. This pamphlet articulates some of these rights and duties.</p>
        </div>

        <div id="ContentTextinner">
          <h3>Your Rights As a Bank Customer</h3>
          <p><strong>The Right to be informed:</strong> As a bank customer, you have a right to disclosure of information from your bank on goods and services the bank offers. The information provided must be complete, relevant and truthful. Your bank must explain to your understanding all contractual terms and charges prior to the consummation of any agreement or contract. This right enables customers to have relevant information in order to make rational choices. It amounts to a breach of your right if your bank fails to provide this information or deliberately misleads you in any way.</p>
          <p><strong>The Right to choose:</strong> You have a right to select from the range of products and services made available by your bank at competitive prices. This means that as a customer, you can, at all times, decide on the product or service to accept/purchase and the ones to decline. It is wrong for a bank to restrict your choices or compel you to accept/purchase products or services that are ill-suited for your needs. Where you are not satisfied with your bank’s service delivery on any product or service, you have the right to end the contract or even the banking relationship provided all outstanding commitments are settled by the customer.</p>
          <p><strong>The Right to safety: </strong> This right requires a bank to guarantee all its customers a secure and conducive banking environment devoid of threats to their safety and health. You have the right to be reasonably protected from accidents while on the premises of your bank. You also have the right to be protected from the negative effects of pollution of any kind whether arising from your bank’s operations or from other sources. It is necessary to stress that your bank is obligated to adhere strictly to applicable safety laws and directives to ensure that your safety and wellbeing are adequately guaranteed while you are on the premises of your bank.</p>
          <p><strong>The Right to privacy and confidentiality: </strong> As a bank customer, you have the right to freedom from disclosure of your account details by the bank as well as intrusion into your account by third party. In other words, your bank must not divulge your account information to a third party; the bank must also protect your information from unauthorised access by a third party.</p>
        
       
        </div>

        <div id="ContentTextinner">
          <h3>Your Duties As a Bank Customer</h3>
          <p><strong>Duty of knowledge and understanding:</strong> This represents the cornerstone of your duties as a bank customer and involves the search for relevant knowledge that should lead you to make informed decisions and enhance your benefits. Without adequate knowledge, customers are bound to make ill-informed decisions which may precipitate an avalanche of complaints from customers against their banks. It is generally agreed that sophistication in the banking industry has tasked the understanding of even people that are financially literate; it is, therefore, your responsibility to “shine your eyes” when dealing with your bank.</p>
          {/* ... (Continue with the rest of the content) */}
        </div>
    </div>
    <Footer />
    </>
  )
}

export default FinEdu