import React from 'react';
import {BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsJustify} from 'react-icons/bs';

function AdminHeader({OpenSidebar}) {
  return (
    <header className='header-admin'>
      <div className='menu-icon'>
        <BsJustify className='icon'  onClick={OpenSidebar}/>
      </div>
      <div className='header-left'>
        <h3>Complaints Page</h3>
      </div>

    </header>
 
  );
};

export default AdminHeader;
