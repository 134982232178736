import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import { format } from 'date-fns';
import '../Dashboard.css';

function Home() {
  const [dashboardData, setDashboardData] = useState(null);
  const [chartFilter, setChartFilter] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 }); // Initialize with the current year and month


  useEffect(() => {
    // Fetch data from your backend API
    fetch(`http://localhost/compbk/dashboard.php`)
      .then((response) => response.json())
      .then((data) => setDashboardData(data))
      .catch((error) => console.error('Error fetching dashboard data:', error));
  }, []);

  if (!dashboardData) {
    return <p>Loading...</p>;
  }

  const { totalComplaints, pendingComplaints, treatedComplaints, cancelledComplaints } = dashboardData;

  const totalComplaintsData = [
    { name: 'Total Complaints', value: totalComplaints },
  ];

  const pendingComplaintsData = [
    { name: 'Pending Complaints', value: pendingComplaints },
  ];
  
  // Chart data
  const chartData = [
    { name: 'Total Complaints', value: totalComplaints },
    { name: 'Pending Complaints', value: pendingComplaints },
    { name: 'Treated Complaints', value: treatedComplaints },
    { name: 'Cancelled Complaints', value: cancelledComplaints },
  ];


  const handleFilterChange = (year, month) => {
    setChartFilter({ year, month });
  };

  const yearOptions = Array.from({ length: 5 }, (_, index) => new Date().getFullYear() - index);
  const monthOptions = Array.from({ length: 12 }, (_, index) => index + 1);


  return (
    <main className='main-container'>
      <div className='main-title'>
        <h3>DASHBOARD</h3>
      </div>

      <div className='main-cards'>
        <div className='card'>
          <div className='card-inner'>
            <h3>TOTAL COMPLAINTS</h3>
            <BsPeopleFill className='card_icon' />
          </div>
          <h1>{totalComplaints}</h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>PENDING COMPLAINTS</h3>
            <BsPeopleFill className='card_icon' />
          </div>
          <h1>{pendingComplaints}</h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>TREATED COMPLAINTS</h3>
            <BsPeopleFill className='card_icon' />
          </div>
          <h1>{treatedComplaints}</h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>CANCELLED COMPLAINTS</h3>
            <BsFillGrid3X3GapFill className='card_icon' />
          </div>
          <h1>{cancelledComplaints}</h1>
        </div>
      </div>

      <div className='charts'>
  {/* Chart 1: Total Complaints */}
  <div className="chart-container">
    <div className="filter-container">
      <label>Filter Total Complaints</label>
      <select
        value={chartFilter.year}
        onChange={(e) => handleFilterChange(parseInt(e.target.value, 10), chartFilter.month)}
      >
        {yearOptions.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <select
        value={chartFilter.month}
        onChange={(e) => handleFilterChange(chartFilter.year, parseInt(e.target.value, 10))}
      >
        {monthOptions.map((month) => (
          <option key={month} value={month}>
            {format(new Date(2000, month - 1), 'MMMM')}
          </option>
        ))}
      </select>
    </div>
    <ResponsiveContainer width='100%' height={300}>
      <BarChart data={totalComplaintsData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='value' fill='#8884d8' />
      </BarChart>
    </ResponsiveContainer>
  </div>

  {/* Chart 2: Pending Complaints */}
  <div className="chart-container">
    <div className="filter-container">
      <label>Filter Pending Complaints</label>
      <select
        value={chartFilter.year}
        onChange={(e) => handleFilterChange(parseInt(e.target.value, 10), chartFilter.month)}
      >
        {yearOptions.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <select
        value={chartFilter.month}
        onChange={(e) => handleFilterChange(chartFilter.year, parseInt(e.target.value, 10))}
      >
        {monthOptions.map((month) => (
          <option key={month} value={month}>
            {format(new Date(2000, month - 1), 'MMMM')}
          </option>
        ))}
      </select>
    </div>
    <ResponsiveContainer width='100%' height={300}>
      <BarChart data={pendingComplaintsData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='value' fill='#8884d8' />
      </BarChart>
    </ResponsiveContainer>
  </div>
</div>

    </main>
  );
}

export default Home;
