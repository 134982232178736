import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import './header.css';
import '../../App.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import LogoImage from './cbnlogotrans.gif';
import backgroundImage from './header.png';

const Head = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    margin: '0',
    position: 'relative',
    zIndex: 1, 
  };

  return (
    <section className="head" style={backgroundStyle}>
      <div className="container flexSB">
        <Link to="/">
          <div className="logo">
            <img src={LogoImage} alt="Logo" className="logo-img" />
            <div className="logo-text">
              <p className="logo-text-primary">Central Bank of Nigeria</p>
              <p className="logo-text-secondary">Online Complaints, Digital Communication and Financial Education Portal</p>
            </div>
          </div>
        </Link>
        <div className="social">
          <i className="fab fa-twitter icon"></i>
          <i className="fab fa-instagram icon"></i>
          <i className="fab fa-facebook-f icon"></i>
          <i className="fab fa-youtube icon"></i>
        </div>
      </div>
    </section>
  );
};

const Header = () => {
  const [click, setClick] = useState(false);
  const history = useNavigate();
  const location = useLocation();

  const iconStyle = {
    color: '#fff',
  };

  const scrollToFAQ = () => {
    setClick(false);

    // Scroll to the FAQ section on the homepage
    if (location.pathname === '/') {
      scroll.scrollTo(900, {
        smooth: true,
        offset: -50, // Adjust the offset if needed
      });
    }
  };
  
  
  

  return (
    <>
      <Head />
      <header>
        <nav className="flexSB">
        <ul className={click ? "mobile-nav" : "flexSB"} onClick={() => setClick(false)}>
            <li><Link to="/"><i className="fa fa-home" style={iconStyle}></i></Link></li>
            <li><Link to="/DigitalComm"> Digital Communication </Link></li>
            <li><Link to="/FinEdu"> Financial Education </Link></li>
            <li><Link to="/ComplaintForm">Submit Complaints</Link></li>
            <li><Link to="#" onClick={scrollToFAQ}> FAQ </Link></li>
            <li><Link to="/Contact"> Contact</Link></li>
          </ul>


          <div className="start">
            <Link to="/ComplaintForm" className="nav-link">
              <div className="button"> Submit Complaints</div>
            </Link>
          </div>
          <button className="toggle" onClick={() => setClick(!click)}>
            {click ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
          </button>
        </nav>
      </header>
    </>
  );
};

export default Header;
