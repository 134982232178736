import React from 'react'
import { Link } from 'react-router-dom';
import "./hero.css"

const Hero = () => {
  return (
    <>
       <section className='hero'>
            <div className="container">
                <div className='row'>
                    <h1 className='complaint-hero'>Welcome to the Consumer Complaints Page of the Central Bank of Nigeria</h1>
                    <p>Providing a seamless and reliable experience. 
                      Your satisfaction is our priority. 
                      If you have encountered any issues or wish to voice your concerns, 
                      you're in the right place. Our Complaints Page is here to ensure your concerns are heard and addressed promptly.</p>
                      
                    <div className="button">
                      <Link to="/ComplaintForm" className="nav-link">
                        <button className='primary-btn'>
                            Get Started Now <i className='fa fa-long-arrow-alt-right'></i>
                        </button>
                      </Link>
                    </div>
                </div>
            </div>
       </section>
       <div className='marigin'></div>
       
    </>
  )
}


export default Hero