import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="copyright-area">
        <div className="container">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">
                <p>Copyright &copy; 2024, All Rights Reserved  Consumer Protection Department</p>
              </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
